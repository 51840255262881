import gql from "graphql-tag"
const RESULT = gql`
  query {
    result @client {
      documents {
        docId
        name
        title
        author
        image
      }
      hasMore
      channelId
      directiveButtons {
        text
        payload
      }
    }
  }
`

RESULT.SELECTED_BUTTONS = gql`
  query {
    selectedButtons @client {
      text
      payload
    }
  }
`
RESULT.fragments = {}

export default RESULT
