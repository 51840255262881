import gql from "graphql-tag"

const USER = {
  IS_LOGGED_IN: gql`
    query{
      session @client{
        isLoggedIn
      }
    }
  `,
  INFO: gql`
    query {
      user {
        id
        email
        name
      }
    }
  `,
  GOOGLE: gql`
    query {
      user {
        id
        google {
          id
          email
          name
          picture
        }
      }
    }
  `,
  FACEBOOK: gql`
    query {
      user {
        id
        facebook {
          id
          name
          fbid
        }
      }
    }
  `,
}

export default USER