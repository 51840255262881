import { setContext } from 'apollo-link-context'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import typeDefs from './typeDefs'
import fetch from 'isomorphic-fetch'
import resolvers from './resolvers'
import CONSTANTS from '../constants'
const httpLink = createHttpLink({ uri: CONSTANTS.MYBEE.API_SERVER })
const authLink = setContext((_, { headers }) => {
    const token =
        typeof window !== 'undefined' &&
        window.localStorage.getItem(`auth_token`)
    return {
        headers: !token
            ? headers
            : {
                  ...headers,
                  authorization: `Bearer ${token}`,
              },
    }
})

const cache = new InMemoryCache()

cache.writeData({
    data: {
        modal: {
          __typename: "Modal",
          share: {
              __typename: "Share",
              open: false,
              link: ''
          },
        },
        session: {
            __typename: 'Session',
            isLoggedIn:
                typeof window !== 'undefined' &&
                !!window.localStorage.getItem('auth_token'),
                user_id: Math.floor(Math.random() * 10e12).toString()
        },
        event: {
            __typename: 'Event',
            start: '',
            end: '',
            name: '',
            description: '',
        },
        popover: {
            __typename: 'Popover',
            user: false,
        },
        drawer: {
            __typename: 'Drawer',
            left: false,
        },
        loading: {
            __typename: 'Loading',
            view: false,
            search: false,
        },
    },
})

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
    typeDefs,
    fetch,
    resolvers,
})

export default client
