import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import CssBaseLine from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from '../../src/theme'
import client from '../../src/client'
import { ApolloProvider } from '@apollo/react-hooks'
import { SnackbarProvider } from 'notistack'
import Navbar from '../../src/containers/NavBar'
import { makeStyles } from '@material-ui/core/styles'
import DesktopFooter from '../../src/containers/Footer/DesktopFooter'
import Loadable from 'react-loadable'
import Share from '../../src/components/Modal/Share'
import Drawer from '../../src/containers/Drawer'
import CustomerChat from '../../src/containers/CustomerChat'
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  body: {
    marginTop: 30,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  child: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    marginTop: theme.spacing(12),
  },
}))
const TopLayout = ({ children }) => {
  const [state, setState] = useState({
    drawer: false,
    collapsed: false
  })
  const classes = useStyles()
  const onOpen = () => {
    setState({ ...state, drawer: true })
  }
  const onClose = () => setState({ ...state, drawer: false })
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>mybee</title>
        <link rel="canonical" href="https://mybee.cf" />
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap"
          rel="stylesheet"
        />
        {/* <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
         */}
      </Helmet>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={1}>
          <ApolloProvider client={client}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                width: '100%',
                marginTop: state.collapsed ? 0 : 80,
              }}
            >
              <CssBaseLine />
              <Navbar open={state.drawer} onOpen={onOpen} onClose={onClose} onCollapse={isCollapsed => {
                setState({
                  ...state,
                  collapsed: isCollapsed
                })
              }}/>
              <Share />
              <Drawer open={state.drawer} onOpen={onOpen} onClose={onClose} />
              {children}
              <div className={classes.grow} />
              <CustomerChat />
            </div>
          </ApolloProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </>
  )
}

export default TopLayout
