import gql from 'graphql-tag'
const SEARCH = gql`
    query {
        search @client {
            documents {
                docId
                cover
                name
                intro
            }
            hasMore
            responseMessage
            directiveButtons {
                text
                payload
            }
            quickReplyMessage
            channelId
            filteredButtons {
                text
                payload
            }
        }
    }
`
export const addNew = (data, client) => {
    if (data) {
        client.writeData({
            data: {
                search: {
                    __typename: 'Search',
                    ...data,
                    filteredButtons: [],
                },
            },
        })
    }
}

export const addMore = (data, client) => {
    if (data) {
        const { search: prev } = client.readQuery({
            query: SEARCH,
        })
        const { documents, ...rest } = data
        client.writeData({
            data: {
                search: {
                    __typename: 'Search',
                    documents: [...prev.documents, ...documents],
                    //   filteredButtons: prev.filteredButtons,
                    ...rest,
                },
            },
        })
    }
}

export const addFilter = (filter, data, client) => {
    if (data) {
        const {
            search: { filteredButtons, message },
        } = client.readQuery({
            query: SEARCH,
        })
        client.writeData({
            data: {
                search: {
                    __typename: 'Search',
                    message,
                    filteredButtons: [
                        ...filteredButtons,
                        {
                            __typename: 'Action',
                            ...filter,
                        },
                    ],
                    ...data,
                },
            },
        })
    }
}
