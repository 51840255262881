import React from "react"
import Typography from "@material-ui/core/Typography"
import { Divider, Link as MLink } from "@material-ui/core"
import {Link} from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
const useStyles = makeStyles(theme => ({
  root: {
      display: 'flex'
  },
  mybee: {
    color: "#FEBB57",
    marginLeft: theme.spacing(1),
  },
  policy: {
    color: "#000000",
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))
const MobileFooter = () => {
  const classes = useStyles()
  const d = new Date()
  return (
    <div style={{ bottom: 0 }}>
      <Divider className={classes.divider} />
      <div className={classes.root}>
        <Typography variant="subtitle2">©{d.getFullYear()} </Typography>
        <MLink
          underline="none"
          className={classes.mybee}
          component="button"
          onClick={() => window.open("https://www.mybeeai.com/")}
        >
          <Typography variant="subtitle2">mybee AI, JSC </Typography>
        </MLink>
      </div>
      <Link
            to={"/privacy_policy.html"}
            className={classes.policy}
          >
        <Typography variant="subtitle2">Privacy Terms</Typography>
      </Link>
    </div>
  )
}

export default MobileFooter
