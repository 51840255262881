import gql from 'graphql-tag'
const SEARCH_RESULT = gql`
    fragment Result on SearchResult {
        documents {
            docId
            cover
            name
            intro
        }
        hasMore
        responseMessage
        responseLanguage
        quickReplyMessage
        directiveButtons {
            text
            payload
        }
    }
`
const PAYLOAD = gql`
    query {
        search @client {
            documents {
                docId
                cover
                name
                intro
            }
            channelId
            hasMore
            responseMessage
            quickReplyMessage
            directiveButtons {
                text
                payload
            }
        }
    }
`
PAYLOAD.fragments = {
    documents: gql`
        fragment Documents on SearchPayload {
            documents {
                docId
                image
                name
                intro
            }
        }
    `,
    directiveButtons: gql`
        fragment DirectiveButtons on SearchPayload {
            directiveButtons {
                text
                payload
            }
        }
    `,
}
const SEARCH = gql`
    query($message: String!, $channelId: String) {
        search {
            new(message: $message, channelId: $channelId) {
                ...Documents
                ...DirectiveButtons
                channelId
                hasMore
                responseMessage
                quickReplyMessage
            }
        }
    }
    ${PAYLOAD.fragments.documents}
    ${PAYLOAD.fragments.directiveButtons}
`

SEARCH.MORE = gql`
    query($channelId: String!) {
        search {
            more(channelId: $channelId) {
                ...Documents
                ...DirectiveButtons
                channelId
                hasMore
                responseMessage
                quickReplyMessage
            }
        }
    }
    ${PAYLOAD.fragments.documents}
    ${PAYLOAD.fragments.directiveButtons}
`

SEARCH.FILTER = gql`
    query($channelId: String!, $text: String!, $payload: String!) {
        search {
            filter(channelId: $channelId, text: $text, payload: $payload) {
                ...Documents
                ...DirectiveButtons
                channelId
                hasMore
                responseMessage
                quickReplyMessage
            }
        }
    }
    ${PAYLOAD.fragments.documents}
    ${PAYLOAD.fragments.directiveButtons}
`
SEARCH.PAYLOAD = PAYLOAD

SEARCH.QUICKREPLIES = gql`
    query {
        searchPayload @client {
            directiveButtons {
                text
                payload
            }
        }
    }
`
// SEARCH.fragments = {
//   documents: gql`
//     fragment documents on search {
//       search {
//         new(message: $message, channelId: $channelId) {
//           documents {
//             docId
//             image
//             name
//           }
//         }
//       }
//     }
//   `,
//   channelId: gql`
//     fragment documents on search {
//       search {
//         new(message: $message, channelId: $channelId) {
//           channelId
//         }
//       }
//     }
//   `,
// }
// SEARCH = {
//   PAYLOAD: {
//     DOCUMENTS: gql`
//       query @client {
//         searchPayload {
//           documents {
//             docId
//             image
//             name
//           }
//         }
//       }
//     `,
//   },
// }

export default SEARCH_RESULT
