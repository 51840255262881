import gql from "graphql-tag"
// const INGREDIENT = gql`
//   fragment Ingredient on Document {
//     ingredients {
//       name
//       amount
//     }
//   }
// `

// const METHOD = gql`
//   fragment Method on Document {
//     method {
//       step
//       text
//       image
//     }
//   }
// `
const DOCUMENT = gql`
  query($docId: String!) {
    document(docId: $docId) {
      name
      intro
      tags
      docId
      ingredients {
        name
        amount
      }
      method {
        number
        text
        images
      }
      author{
        name
        source
        domain
      }
      cover
      dataType
      level
      serving
      taste
      totalTime
      redirectURL
      relatedYoutubeVideoIds
    }
  }
`

export default DOCUMENT
