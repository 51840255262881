import React from 'react'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'
import Backdrop from '@material-ui/core/Backdrop'
import { Modal, Fade, makeStyles } from '@material-ui/core'
const SHARE = gql`
  query {
    modal @client {
      share {
        open
        link
      }
    }
  }
`
const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))
const Share = () => {
  const classes = useStyles()
  const {
    data: {
      modal: {
        share: { open, link },
      },
    },
    client,
  } = useQuery(SHARE)
  const onClose = () => {
    client.writeData({
      data: {
        modal: {
          __typename: 'Modal',
          share: {
            __typename: 'Share',
            open: false,
            link: '',
          },
        },
      },
    })
  }
  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <h1>This is a modal</h1>
        </div>
      </Fade>
    </Modal>
  )
}

export default Share
