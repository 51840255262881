import React, { useState, useEffect, useImperativeHandle } from 'react'
import Loadable from 'react-loadable'
import { makeStyles } from '@material-ui/core/styles'
import { AppBar, Toolbar, IconButton, LinearProgress, useMediaQuery } from '@material-ui/core'
import clsx from 'clsx'
import MenuIcon from '@material-ui/icons/Menu'
import gql from 'graphql-tag'
import { useQuery } from 'react-apollo'
import { Collapse } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import useOnCloseScrollY from '../../utils/scrollHelper'
// import User from "../../components/User"
const Loading = gql`
  query {
    loading @client {
      search
    }
  }
`
const Drawer = Loadable({
  loader: () => import('../Drawer'),
  loading: () => null,
})

const Logo = Loadable({
  loader: () => import('./Logo'),
  loading: () => null,
})
const SearchBox = Loadable({
  loader: () => import('../../components/SearchBox'),
  loading: () => null,
  render: (loaded, props) => {
    const Components = loaded.default
    return <Components {...props} />
  },
})

const User = Loadable({
  loader: () => import('../../components/User'),
  loading: () => null,
})

function CollapseOnScroll(props) {
  const { children, ...other } = props
  const { isCollapsed, ...remaining } = other
  return (
    <Collapse {...remaining} in={!isCollapsed}>
      {children}
    </Collapse>
  )
}

const useStyles = makeStyles(theme => {
  const drawerWidth = 300 + theme.spacing(1)
  return {
    grow: {
      flexGrow: 1,
      color: '#ffffff',
      height: 60,
      [theme.breakpoints.down('sm')]: {
        height: 40,
      },
    },
    sectionDesktop: {
      display: 'flex',
    },
    toolbar: {
      alignContent: 'center',
      justifyContent: 'center',
      padding: theme.spacing(1, 1),
    },
    appbar: {
      marginBottom: 10,
    },
    appBarShift: {
      [theme.breakpoints.up('md')]: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
    menuIcon: {
      marginRight: theme.spacing(0.5),
      padding: theme.spacing(0.25, 0.25),
    },
    hide: {
      display: 'none',
    },
  }
})

const NavBar = ({ open, onOpen, onCollapse }) => {
  const { data: { loading } = {} } = useQuery(Loading)
  const classes = useStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))
  const isCollapsed = useOnCloseScrollY({ threshold: 400 })
  useEffect(() => {
    onCollapse(isCollapsed)
  }, [isCollapsed])
  return (
    <CollapseOnScroll
      component={AppBar}
      isCollapsed={matches && isCollapsed}
      position="fixed"
      color="inherit"
      className={clsx(classes.appBar, {
        // [classes.appBarShift]: open,
      })}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          onClick={onOpen}
          className={clsx(classes.menuIcon, {
            // [classes.hide]: open
          })}
        >
          <MenuIcon />
        </IconButton>
        <Logo />
        <SearchBox />
        <div className={classes.grow} />
        <div className={classes.sectionDesktop}>
          <User />
        </div>
      </Toolbar>
      {loading && loading.search && <LinearProgress color="secondary" />}
    </CollapseOnScroll>
  )
}

export default NavBar
