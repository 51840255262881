import { createMuiTheme } from "@material-ui/core/styles"

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#FEBB57",
      hover: "#FDD393",
      second: "#361807",
    },
    secondary: {
      main: "#1e88e5",
    },
  },
  drawer: {
    width: 300,
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Noto Sans",
      "Ubuntu",
      "Cantarell",
      "Helvetica Neue",
      "sans-serif",
      "Apple Color Emoji",
      "Segoe UI Emoji",
      "Segoe UI Symbol",
      "Noto Color Emoji",
    ].join(","),
  },
})

export default theme
