import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Loadable from 'react-loadable'
import {
    Drawer as RootDrawer,
    IconButton,
    useMediaQuery,
    Typography,
    Divider,
} from '@material-ui/core'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import ClearlIcon from '@material-ui/icons/Clear'
import MobileFooter from '../Footer/MobileFooter'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import clsx from 'clsx'
import MenuIcon from '@material-ui/icons/Menu'
import ChatIcon from '@material-ui/icons/Chat'
const FilteredButtons = Loadable({
    loader: () => import('../../components/FilteredButtons'),
    loading: () => null,
})
const Logo = Loadable({
    loader: () => import('../NavBar/Logo'),
    loading: () => null,
})
const DirectiveButtons = Loadable({
    loader: () => import('../../components/DirectiveButtons'),
    loading: () => null,
})
const useStyles = makeStyles(theme => {
    const drawerWidth = 300 + theme.spacing(1)
    return {
        name: {
            marginLeft: theme.spacing(1),
            padding: theme.spacing(0.5, 0.5),
        },
        root: {
            width: drawerWidth,
            padding: theme.spacing(1, 1),
            marginTop: theme.spacing(7),
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawerOpen: {
            width: drawerWidth,
            zIndex: 1000,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            zIndex: 1000,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(6) + 1,
            // [theme.breakpoints.up("sm")]: {
            //   width: theme.spacing(9) + 1,
            // },
        },
        toolbar: {
            display: 'flex',
            flexDirection: 'column',
            whiteSpace: 'wrap',
            height: '100%',
            width: drawerWidth,
            padding: theme.spacing(0, 1),
            // paddingBottom: theme.spacing(1)
            // ...theme.mixins.toolbar,
        },
        menuIcon: {
            marginRight: theme.spacing(0.5),
            padding: theme.spacing(0.25, 0.25),
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        headerClose: {
            opacity: 0,
            marginTop: theme.spacing(6),
        },
        grow: {
            flexGrow: 1,
        },
        paper: {
            height: '100%',
        },
        divider: {
            margin: theme.spacing(2, 0),
        },
        sizeSmall: {
            padding: 0,
        },
    }
})

const Drawer = ({ open, onClose, onOpen }) => {
    // const matches = useMediaQuery(theme => theme.breakpoints.down('sm'))
    const classes = useStyles()
    return (
        <RootDrawer
            open={open}
            onClose={onClose}
            variant={'temporary'}
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
            }}
        >
            <div className={classes.toolbar}>
                <div
                    className={clsx(classes.header, {
                        [classes.headerClose]: !open,
                    })}
                >
                    {/* <Typography variant="h6" color="secondary">
            Nút trả lời nhanh
          </Typography>
          <div className={classes.grow} /> */}
                    <IconButton
                        onClick={onClose}
                        size="small"
                        className={classes.menuIcon}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Logo />
                    {/* <Typography variant="h5" className={classes.name}>
                        Mybee
                    </Typography> */}
                </div>
                <Divider />
                {/* {open ? (
          <>
            <Divider className={classes.divider} />
            <DirectiveButtons buttons={directiveButtons} />
            <FilteredButtons buttons={filteredButtons} />
          </>
        ) : (
          <IconButton
            onClick={onOpen}
            style={{ alignSelf: "flex-start", paddingBottom: 8 }}
            size="small"
            classes={{
              sizeSmall: classes.sizeSmall
            }}
          >
            <ChatIcon />
          </IconButton>
        )} */}
                <div style={{ flexGrow: 1 }} />
                {!open ? (
                    <IconButton
                        onClick={onOpen}
                        style={{ alignSelf: 'flex-start', paddingBottom: 8 }}
                        size="small"
                        classes={{
                            sizeSmall: classes.sizeSmall,
                        }}
                    >
                        <InfoIcon color="primary" />
                    </IconButton>
                ) : (
                    <MobileFooter />
                )}
            </div>
        </RootDrawer>
    )
}

export default Drawer
