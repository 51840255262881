import React, { useEffect } from "react"
import fb from "../../utils/facebook"
import CONSTANTS from "../../constants"
const CustomerChat = () => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      fb(FB => timeout && FB.XFBML.parse())
    }, 2000)
    return () => {
      clearTimeout(timeout)
      // typeof window !== 'undefined' && delete timeout;
    }
  }, [])
  return (
    <div
      className="fb-customerchat"
      attribution="setup_tool"
      page_id={CONSTANTS.FACEBOOK.PAGE_ID}
      greeting_dialog_display="hide"
      theme_color="#371807"
    />
  )
}

export default CustomerChat
