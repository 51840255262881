import gql from "graphql-tag"

const typeDefs = gql`
  extend type Query {
    event: Event!
    session: Session!
    popover: Popover!
    modal: Modal!
    drawer: Drawer!
    search: Search!
    loading: Loading!
  }
  type Popover {
    user: Boolean!
  }
  type Modal {
    login: Boolean!
  }
  type Drawer {
    left: Boolean!
  }
  type Session {
    isLoggedIn: Boolean!
  }
  type Modal {
    open: Boolean!
    login: Boolean!
    calendarList: Boolean!
    signupSuccess: Boolean!
    createCalendar: Boolean!
    dateTimePicker: Boolean!
  }
  type Event {
    start: String!
    end: String
    summary: String!
    description: String
  }

  type Search {
    filteredButtons: [Action]!
    directiveButtons: [Action]!
    responseMessage: String
    quickReplyMessage: String
    hasMore: Boolean!
    channelId: String!
    documents: [Document]!
    message: String
  }

  type Document {
    name: String!
    intro: String!
    cover: String!
    docId: String!
  }

  type Action {
    text: String!
    payload: String!
  }
  type Loading{
    search: Boolean!
    view: Boolean!
  }
`
export default typeDefs
