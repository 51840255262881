import QUERY from "../query"
import { differenceBy } from "lodash"
import gql from "graphql-tag"
import { addMore, addNew, addFilter } from "./utils/search"
const SEARCH = gql`
  query(
    $message: String
    $type: String!
    $channelId: String!
    $filter: String
    $filterType: String
  ) {
    search(
      message: $message
      type: $type
      channelId: $channelId
      filter: $filter
      filterType: $filterType
    ) {
      documents {
        docId
        cover
        name
        intro
      }
      channelId
      hasMore
      responseMessage
      responseLanguage
      quickReplyMessage
      directiveButtons {
        text
        payload
      }
    }
  }
`
const CHANNEL_ID = gql`
  query {
    search @client {
      channelId
    }
  }
`
const randomString = () =>
  Math.random()
    .toString(36)
    .substring(2, 15) +
  Math.random()
    .toString(36)
    .substring(2, 15)
const Query = {}
const Mutation = {
  search: async (_, { message, type, filter, filterType }, { client }) => {
    client.writeData({
      data: {
        loading: {
          __typename: "Loading",
          search: true,
        },
      },
    })
    let channelId
    if (type === "new") {
      channelId = randomString()
    } else {
      const { search } = client.readQuery({ query: CHANNEL_ID })
      channelId = search.channelId
    }
    const { data } = await client.query({
      query: SEARCH,
      variables: {
        message,
        channelId,
        type,
        filter,
        filterType,
      },
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    })
    client.writeData({
      data: {
        loading: {
          __typename: "Loading",
          search: false,
        },
      },
    })
    if (data) {
      let { search } = data
      if(!search.responseLanguage){
        search.responseLanguage = 'vn'
      }
      switch (type) {
        case "new":
          addNew({ ...search, message }, client)
          return
        case "more":
          addMore(search, client)
          return
        case "filter":
          addFilter({ text: filter, payload: filterType }, search, client)
          return
        default:
          return
      }
    }
    client.writeData({
      data: {
        search: {
          __typename: "Search",
          hasMore: false,
        },
      },
    })
  },
  searchMore: async (_, __, { client, cache }) => {
    const {
      searchPayload: { documents, channelId },
    } = cache.readQuery({
      query: QUERY.SEARCH.PAYLOAD,
    })
    const { data } = await client.query({
      query: QUERY.SEARCH.MORE,
      variables: {
        channelId,
      },
      fetchPolicy: "no-cache",
    })
    const {
      search: {
        more: { documents: newDocs, ...remains },
      },
    } = data
    cache.writeData({
      data: {
        searchPayload: {
          __typename: "SearchPayload",
          documents: [...documents, ...newDocs],
          ...remains,
        },
      },
    })
  },
  searchFilter: async (_, { text, payload }, { client, cache }) => {
    const { selectedButtons } = cache.readQuery({
      query: QUERY.RESULT.SELECTED_BUTTONS,
    })
    const {
      searchPayload: { channelId },
    } = cache.readQuery({
      query: QUERY.SEARCH.PAYLOAD,
    })
    const {
      data: {
        search: {
          filter: { directiveButtons, ...remains },
        },
      },
    } = await client.query({
      query: QUERY.SEARCH.FILTER,
      variables: {
        text,
        payload,
        channelId,
      },
      fetchPolicy: "no-cache",
    })
    cache.writeData({
      data: {
        searchPayload: {
          __typename: "SearchPayload",
          ...remains,
          directiveButtons: differenceBy(
            directiveButtons,
            selectedButtons,
            "text"
          ),
        },
        selectedButtons: [
          ...selectedButtons,
          { __typename: "DirectiveButton", text, payload },
        ],
      },
    })
  },
}

const resolvers = {
  // Mutation,
  Query,
}
export default resolvers
