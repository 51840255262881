import USER from './USER'
import SEARCH_RESULT from './SEARCH_RESULT'
import RESULT from './RESULT'
import DOCUMENT from './DOCUMENT'
const QUERY = {
    USER,
    SEARCH_RESULT,
    RESULT,
    DOCUMENT,
}

export default QUERY
