import loadScript from 'load-script'
import CONSTANTS from '../constants'

let initialized = false
let queue = []

const fb = callback => {
  if (initialized) {
    callback(window.FB)
  } else {
    queue.push(callback)
    if (!window.fbAsyncInit) {
      window.fbAsyncInit = () => {
        window.FB.init({
          appId: CONSTANTS.FACEBOOK.APP_ID,
          autoLogAppEvents: true,
          status: true,
          cookie: true,
          xfbml: false,
          version: 'v5.0',
        })
        initialized = true
        queue.forEach(cb => cb(window.FB))
        queue = null
      }
      const script =
        window.localStorage.getItem('fb:debug') === 'true'
          ? 'xfbml.customerchat/debug.js'
          : 'xfbml.customerchat.js'
      loadScript(`https://connect.facebook.net/en_US/sdk/${script}`, {
        async: true,
      })
    }
  }
}

export const checkLoginStatus = callback =>
  fb(FB => FB.getLoginStatus(res => callback(res)))

export const facebookLogin = callback => {
  checkLoginStatus(({ status, authResponse }) => {
    console.log(status)
    if (status === 'connected') {
      callback(authResponse)
    } else {
      fb(FB =>
        FB.login(res => callback(res.authResponse), {
          auth_type: 'reauthorize',
        })
      )
    }
  })
}

export const getUserInfo = callback => {
  checkLoginStatus(({ status }) => {
    if (status === 'connected') {
      return fb(FB =>
        FB.api('/me?fields=id,name,picture', response => {
          callback(response)
        })
      )
    } else {
      return null
    }
  })
}
export default fb
