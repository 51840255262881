// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-debug-appbar-js": () => import("./../../../src/pages/debug/appbar.js" /* webpackChunkName: "component---src-pages-debug-appbar-js" */),
  "component---src-pages-debug-card-js": () => import("./../../../src/pages/debug/card.js" /* webpackChunkName: "component---src-pages-debug-card-js" */),
  "component---src-pages-debug-list-js": () => import("./../../../src/pages/debug/list.js" /* webpackChunkName: "component---src-pages-debug-list-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-privacy-policy-html-js": () => import("./../../../src/pages/privacy_policy.html.js" /* webpackChunkName: "component---src-pages-privacy-policy-html-js" */),
  "component---src-pages-term-of-service-html-js": () => import("./../../../src/pages/term_of_service.html.js" /* webpackChunkName: "component---src-pages-term-of-service-html-js" */),
  "component---src-pages-test-omg-js": () => import("./../../../src/pages/test/omg.js" /* webpackChunkName: "component---src-pages-test-omg-js" */),
  "component---src-pages-view-js": () => import("./../../../src/pages/view.js" /* webpackChunkName: "component---src-pages-view-js" */)
}

